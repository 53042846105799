import { Link, withPrefix } from 'gatsby'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'

import {
  Button,
  CheckboxWrapper,
  Form,
  FormBottomWrapper,
} from '../../elements/forms'
import { Checkbox } from '../../forms/input'
import { Regulations } from './regulations'
import { TosWrapper } from '../class/step2'

export const Step3Form = ({ isLoading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      tos4: false,
    },
  })

  return (
    <TosWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormBottomWrapper xs={12}>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <CheckboxWrapper xs={12}>
                  <Regulations />
                </CheckboxWrapper>
                <CheckboxWrapper xs={12}>
                  <Checkbox
                    {...register('tos4', { required: true })}
                    control={control}
                    label={
                      <>
                        Oświadczam, że zapoznałam/zapoznałem się z treścią Regulaminu Konkursu dla Klas I-VI Szkół Podstawowych „Misja Kieszonkowe 2024” (dalej „Regulamin Konkursu”) oraz akceptuję jego treść, co jest równoznaczne ze zgłoszeniem wskazanej w formularzu Placówki do udziału w Konkursie dla Klas I-VI Szkół Podstawowych „Misja Kieszonkowe 2024” (dalej „Konkurs”) i wskazaniem Klasy, która ma brać udział w tym Konkursie. Jednocześnie oświadczam, że Regulamin Konkursu został udostępniony rodzicom lub opiekunom prawnym uczniów Klasy, którzy wyrazili zgodę na udział podopiecznych w Konkursie na zasadach określonych w Regulaminie Konkursu oraz korzystanie z pracy autorstwa swojego podopiecznego na potrzeby Konkursu (
                        <a
                          href={withPrefix('/docs/MK2024-regulamin-konkursu-Misja-Kieszonkowe.pdf')}
                          target="_blank"
                        >
                          Pobierz Regulamin Konkursu
                        </a>
                        ).
                      </>
                    }
                    error={errors.tos4}
                  />
                </CheckboxWrapper>
                <CheckboxWrapper xs={12}>
                  <Button as="button" className="btn" disabled={isLoading}>
                    {isLoading && <>Wysyłanie zgłoszenia</>}
                    {!isLoading && <>Wyślij zgłoszenie</>}
                  </Button>
                </CheckboxWrapper>
              </Row>
            </Col>
          </Row>
        </FormBottomWrapper>
      </Form>
    </TosWrapper>
  )
}
