import filesize from 'filesize'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import closeIcon from '../../images/close_bold_gray_icon.svg'

const DropzoneWrapper = styled.div`
  border-radius: 0.25rem;
  border: 1px solid transparent;
  position: relative;
  background-color: #d6d6d6;
  text-align: left;
  transition: ease 0.2s all;
  padding: 14px;
  z-index: 20;

  ${(props) =>
    props.isDragAccept &&
    `
    border-color: ${props.theme.colorGreenLight};
  `}
  ${(props) =>
    props.isDragReject &&
    `
    border-color: ${props.theme.colorRed};
  `}

  cursor: pointer;
  @media (max-width: 767px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  p,
  span {
    color: ${(props) => props.theme.colorGreenLight};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }
  p {
    padding-left: 15px;
  }

  ul {
    list-style-type: none;
    padding-left: 15px;
    margin-bottom: 0;
  }
`

const DropzoneCloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 16px;
  height: 16px;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-50%);
  z-index: 30;
  @media (min-width: 768px) {
    display: none;
  }
`

export const DropzoneField = ({ name, control, ...rest }) => {
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <Dropzone {...rest} onChange={onChange} />
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  )
}

const Dropzone = ({ onChange, show, ...rest }) => {
  const [files, setFiles] = useState([])
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...rest,
    onDropRejected: (files) => {
      files.forEach((f) => {
        if (typeof f.errors !== 'undefined') {
          f.errors.forEach((e) =>
            e.code === 'file-too-large'
              ? alert(
                'Plik jest za duży. Maksykmalny rozmiar pliku to 30MB. Załącz mniejszy plik.'
              )
              : null
          )
        }
      })
    },
    onDrop: useCallback((newFiles) => {
      setFiles(newFiles)
      if (newFiles.length === 1) {
        onChange(newFiles[0])
      }
    }),
  })

  useEffect(() => {
    // reset files when hidden
    if (!show) {
      setFiles([])
    }
  }, [show])

  const addedFiles = files.map((file) => (
    <li key={file.path}>
      {file.path} ({filesize(file.size)})<br />
      <span>Praca dodana, kliknij DALEJ poniżej</span>
    </li>
  ))

  return (
    <DropzoneWrapper
      {...getRootProps()}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
    >
      <input {...getInputProps()} />

      {addedFiles.length > 0 && <ul>{addedFiles}</ul>}
      {addedFiles.length === 0 && (
        <p>
          Kliknij tutaj i wybierz plik lub przenieś i upuść plik na to miejsce.
          <br />
          Maksymalny rozmiar pliku to 30MB.
        </p>
      )}
    </DropzoneWrapper>
  )
}
