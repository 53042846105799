import { Link, withPrefix } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { enableBodyScroll } from 'body-scroll-lock'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'

import {
  Button,
  Form,
  FormBottomWrapper,
  CheckboxWrapper as CW,
} from '../../elements/forms'
import { Checkbox } from '../../forms/input'

export const CheckboxWrapper = styled(CW)`
  small {
    display: inline-block;
    line-height: 1.1em;

    a {
      color: ${(props) => props.theme.colorGreenLight};
    }
  }
`

export const TosWrapper = styled.div`
  background-color: #edf1f3;
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0 0 200px ${(props) => props.theme.colorGreen};
  height: 75vh;
  left: 50%;
  overflow: auto;
  padding: 1rem;
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 9999;
  width: 75%;

  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
  }

  h2 {
    color: ${(props) => props.theme.colorGreenLight};

    @media (max-width: 1229px) {
      font-size: 1rem;
    }
  }
`

export const Step2Form = ({ onSubmit }) => {
  const wrapperRef = useRef()
  useEffect(() => {
    enableBodyScroll(wrapperRef.current)
  }, [])

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      tos1: false,
      tos2: false,
      tos3: false,
    },
  })

  return (
    <TosWrapper ref={wrapperRef}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormBottomWrapper xs={12}>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <CheckboxWrapper xs={12}>
                  <Checkbox
                    {...register('tos1', { required: true })}
                    control={control}
                    label={
                      <>
                        Oświadczam, że uzyskałam/uzyskałem zgodę Dyrektora Placówki (oraz inne zgody, jeżeli są wymagane do wzięcia przez Szkołę udziału w Konkursie), w której jestem zatrudniona/zatrudniony na zgłoszenie udziału Szkoły w konkursie dla Klas I-VI Szkół Podstawowych „Misja Kieszonkowe 2024” (dalej „Konkurs”) oraz wskazanie Klasy, która ma brać udział w Konkursie.
                      </>
                    }
                    error={errors.tos1}
                  />
                </CheckboxWrapper>

                <CheckboxWrapper xs={12}>
                  <Checkbox
                    {...register('tos2', { required: true })}
                    control={control}
                    label={
                      <>
                        Oświadczam, że jestem autorem relacji oraz zdjęć z realizacji zajęć składających się na Pracę Konkursową, w celach określonych w Regulaminie Konkursu, a twórcami lub współtwórcami Pracy Konkursowej są uczniowie Klasy. Ponadto oświadczam, że Praca Konkursowa nie narusza żadnych praw osób trzecich, a w szczególności praw autorskich, praw własności przemysłowej, dóbr osobistych i innych praw i dóbr prawnie chronionych. Jednocześnie oświadczam, że zostały uzyskane zgody na wykorzystanie wizerunków uczniów Klas utrwalonych w Pracy Konkursowej, zgodnie z postanowieniami Regulaminu konkursu.
                      </>
                    }
                    error={errors.tos2}
                  />
                </CheckboxWrapper>

                <CheckboxWrapper xs={12}>
                  <Checkbox
                    {...register('tos3', { required: true })}
                    control={control}
                    label={
                      <>
                        Oświadczam, że zapoznałam/zapoznałem się z treścią Regulaminu strony internetowej oraz akceptuję jego treść (<a
                          href={withPrefix(
                            '/docs/Regulamin_korzystania_ze_Strony.pdf'
                          )}
                          target="_blank"
                        >
                          Pobierz regulamin
                        </a>).
                      </>
                    }
                    error={errors.tos3}
                  />
                </CheckboxWrapper>

                <CheckboxWrapper xs={12}>
                  <small>
                    Informujemy, że administratorem Pani/Pana danych jest BNP Paribas Bank Polska S.A. z siedzibą w Warszawie (01-211), przy ulicy Kasprzaka 2. Przetwarzane dane są w celu obsługi zgłoszeń do Konkursu, przeprowadzenia Konkursu, udziału Szkoły oraz uczestnictwo Klasy w Konkursie. Ma Pani/Pan prawo dostępu do danych oraz ich sprostowania, usunięcia, sprzeciwu wobec przetwarzania i przenoszenia. Ma Pan/Pani również prawo do wniesienia sprzeciwu wobec przetwarzania danych oraz wniesienia skargi do Urzędu Ochrony Danych Osobowych.
                    <br />
                    <a
                      href={withPrefix('/docs/Ochrona danych osobowych.pdf')}
                      target="_blank"
                    >
                      Kliknij i dowiedz się więcej o ochronie danych
                    </a>
                    .
                  </small>
                </CheckboxWrapper>
                <CheckboxWrapper xs={12}>
                  <Button as="button" className="btn">
                    Dalej
                  </Button>
                </CheckboxWrapper>
              </Row>
            </Col>
          </Row>
        </FormBottomWrapper>
      </Form>
    </TosWrapper>
  )
}
