import axios from 'axios'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Link } from 'gatsby'
import isEmail from 'is-email'
import React from 'react'
import { useEffect, useState } from 'react'
import { IdProvider } from 'react-use-id-hook'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { SubmitButton as ClassSubmitButton } from './zgloszenie-klasy'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import PopoverInfo from '../../../components/forms/popover-info'
import ModalConfratulate from '../../../components/forms/modal-congratulate'
import { DropzoneField } from '../../../components/forms/dropzone'
import { Checkbox, Input } from '../../../components/forms/input'
import {
  RegionSelect,
  CitySelect,
  GenericSelect,
  SchoolSelect,
} from '../../../components/forms/select'
import { Step2Form } from '../../../components/forms/entry/step2'
import { Step3Form } from '../../../components/forms/entry/step3'

import {
  PageInnerWrapper,
  Title,
  SubTitle,
  Button,
  DropzoneButton,
  Form,
  FormItemWrapper,
  FormBottomWrapper,
  CheckboxWrapper,
  NotesWrapper,
  NotesIcon,
  FormElementWrapper,
  FormPromptWrapper,
  FormPrompt,
} from '../../../components/elements/forms'
import { FormPromptinnerWrapper } from '../../../components/elements/FormPromptinnerWrapper'
import Back from '../../../components/elements/back'
import Contact from '../../../components/teachers/contact'

import binIcon from '../../../images/bin_icon.svg'

const SubmitWorkPageInnerWrapper = styled(PageInnerWrapper)`
  padding-bottom: 30px;
`

const SubmitTitle = styled(Title)``

const SubmitSubTitle = styled(SubTitle)``

const SubmitDropzoneButton = styled(DropzoneButton)`
  line-height: 0.6;
  padding-left: 10px;
  padding-right: 10px;
  @media (min-width: 768px) {
    width: calc(40% - 10px);
    height: 100%;
    margin-right: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    display: none;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  &:before {
    content: none;
  }
  & > span {
    span {
      font-family: ${(props) => props.theme.fontBNPPSans};
      font-size: 12px;
      font-weight: 300;
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
`

const SubmitDropzoneAddButton = styled(SubmitDropzoneButton)`
  ${(props) =>
    props.error &&
    `
    background-color: ${props.theme.colorRed};
  `}

  @media (min-width: 768px) {
    width: calc(40% - 10px);
    height: 100%;
    margin-right: 10px;
    ${(props) =>
    props.show &&
    `
      display: none;
    `};
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0;
  }
`

const SubmitDropzoneDeleteButton = styled(SubmitDropzoneButton)`
  background-color: ${(props) => props.theme.colorWhite};
  color: #707070 !important;
  border: 1px solid #707070;
  @media (min-width: 768px) {
    ${(props) =>
    !props.show &&
    `
      display: none;
    `};
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
  @media (max-width: 767px) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
  & > span {
    background-image: url(${binIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 17%;
    position: relative;
    padding-right: 30px;
  }
`

const SubmitButton = styled(ClassSubmitButton).attrs({
  type: 'submit',
})`
  @media (min-width: 768px) {
    width: calc(60% - 10px);
    height: 100%;
    margin-left: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
`

const SubmitForm = styled(Form)``

const SubmitFormItemWrapper = styled(FormItemWrapper)``

const SubmitFormBottomWrapper = styled(FormBottomWrapper)``

const SubmitCheckboxWrapper = styled(CheckboxWrapper)``

const SubmitDropzoneWrapper = styled(CheckboxWrapper)`
  @media (min-width: 768px) {
    ${(props) =>
    !props.show &&
    `
      display: none;
    `};
  }
`

const SubmitNotesWrapper = styled(NotesWrapper)``

const SubmitNotesIcon = styled(NotesIcon)``

const SubmitEntryPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [city, setCity] = useState()
  const [region, setRegion] = useState()
  const [activeFocusID, setActiveFocusID] = useState('')
  const [showEmailError, setShowEmailError] = useState(false)
  const [showDropZone, setShowDropZone] = useState(false)
  const [values, setValues] = useState({})
  const [showPolicy1Modal, setShowPolicy1Modal] = useState(false)
  const [showPolicy2Modal, setShowPolicy2Modal] = useState(false)
  const [showThanksModal, setShowThanksModal] = useState(false)

  const defaultClassLevelOptions = [
    { value: '1', label: 'I' },
    { value: '2', label: 'II' },
    { value: '3', label: 'III' },
    { value: '4', label: 'IV' },
    { value: '5', label: 'V' },
    { value: '6', label: 'VI' },
  ]
  const [classLevelOptions, setClassLevelOptions] = useState(
    defaultClassLevelOptions
  )

  const classNameOptions = [...Array(26)]
    .map((q, w) => String.fromCharCode(w + 97))
    .map((v) => ({ value: v.toUpperCase(), label: v.toUpperCase() }))

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      tos1: false,
      tos2: false,
      tos3: false,
      tos4: false,
    },
  })
  const watchCity = watch('city', null)
  const watchRegion = watch('region', null)

  const onSubmitStep1 = (v) => {
    setValues(v)
    setShowPolicy1Modal(true)
  }

  const onSubmitStep2 = (v) => {
    setValues({ ...values, ...v })
    setShowPolicy1Modal(false)
    setShowPolicy2Modal(true)
  }

  const onSubmit = (v) => {
    setIsLoading(true)
    axios
      .post(process.env.GATSBY_MK_API_INITIATE_UPLOAD_URL, {
        name: values.file.name,
        contentType: values.file.type,
      })
      .then((resp) => {
        const fileKey = resp.data.fileKey
        const uploadUrl = resp.data.url

        axios
          .put(uploadUrl, values.file, {
            headers: { 'Content-Type': values.file.type },
          })
          .then((resp) => {
            const data = {
              teacherName: values.teacherName,
              region: values.region.value,
              city: values.city.value,
              facilityName: values.facilityName.label,
              facilityId: values.facilityName.value,
              teacherPhone: values.teacherPhone,
              teacherEmail: values.teacherEmail,
              classLevel: values.classLevel.value,
              className: values.className.value,
              numberOfKids: values.numberOfKids,
              tos1: values.tos1,
              tos2: values.tos2,
              tos3: values.tos3,
              tos4: values.tos4,
              fileKey,
            }
            axios
              .post(process.env.GATSBY_MK_API_SUBMIT_ENTRY_URL, data)
              .then((resp) => {
                setIsLoading(false)
                setShowPolicy1Modal(false)
                setShowPolicy2Modal(false)
                setShowThanksModal(true)
                clearAllBodyScrollLocks()
                reset()
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  alert(
                    'Nie można przesłać zgłoszenia. Sprawdź czy adres e-mail nie został już użyty do rejestracji.'
                  )
                } else {
                  alert(
                    'Przepraszamy, wystąpił błąd. Prosimy spróbować ponownie później.'
                  )
                }

                setIsLoading(false)
                setShowPolicy1Modal(false)
                setShowPolicy2Modal(false)
                clearAllBodyScrollLocks()
                reset()
              })
          })
      })
  }

  const handlerFocus = (e) => {
    if (e.currentTarget !== e.target) {
      setActiveFocusID(e.currentTarget.id)
    }
  }

  const handlerBlur = (e) => {
    if (e.currentTarget !== e.target) {
      setActiveFocusID('')
    }
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setActiveFocusID('')
    }
  }

  const handlerShowDropZone = () => {
    setShowDropZone(!showDropZone)
  }

  useEffect(() => {
    setValue('facilityName', null)

    if (!watchCity) {
      setCity(null)
    } else {
      setCity(watchCity.value)
    }
  }, [watchCity])

  useEffect(() => {
    setValue('city', null)
    setValue('facilityName', null)

    if (!watchRegion) {
      setRegion(null)
    } else {
      setRegion(watchRegion.value)
    }
  }, [watchRegion]);

  return (
    <IdProvider>
      <SEO title="Zgłoszenie pracy konkursowej - Strefa nauczyciela" />
      <Layout>
        {showEmailError && (
          <ModalConfratulate
            title="Uwaga!"
            text={
              <>
                <p>
                  {showEmailError === 1
                    ? `Prosimy najpierw zgłosić klasę do programu.`
                    : `Twoje zgłoszenie oczekuje na rozpatrzenie lub zostało już zaakceptowane.`}
                </p>
              </>
            }
            link="/strefa-nauczyciela/konkurs/zgloszenie-klasy"
            textLink="Zgłoszenie klasy"
          />
        )}
        {showThanksModal && (
          <ModalConfratulate
            text={
              <>
                <p>
                  Twoje zgłoszenie konkursowe zostało przesłane.
                </p>
                <p>
                  <strong>
                    Niebawem drogą mailową poinformujemy Cię o{'\u00A0'}rezultacie weryfikacji.
                  </strong>
                </p>
              </>
            }
            link="/strefa-nauczyciela/konkurs"
            textLink="Wróć na stronę konkursu"
          />
        )}
        <SubmitWorkPageInnerWrapper>
          <Container>
            <Back
              link="/strefa-nauczyciela/"
              name="Powrót do strefy nauczyciela"
            />
            <Row>
              <Col>
                <SubmitTitle>Zgłoszenie pracy konkursowej</SubmitTitle>
                <SubmitSubTitle>
                  * Wszystkie pola są obowiązkowe do{'\u00A0'}prawidłowego
                  zgłoszenia do{'\u00A0'}konkursu
                </SubmitSubTitle>
              </Col>
            </Row>

            <SubmitForm onSubmit={handleSubmit(onSubmitStep1)}>
              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherEmail"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('teacherEmail', {
                          required: true,
                          validate: async (value) => {
                            if (!isEmail(value)) return false
                            const response = await axios.post(
                              `${process.env.GATSBY_MK_API_CHECK_CLASS_EMAIL}`,
                              {
                                email: value,
                              }
                            )
                            if (!response.data.allow) {
                              setShowEmailError(1)
                              return false
                            } else if (!response.data.allow2) {
                              setShowEmailError(2)
                              return false
                            }
                            setShowEmailError(false)
                            /*setClassLevelOptions(
                              defaultClassLevelOptions.filter(
                                (clO) => clO.value === response.data.classLevel
                              )
                            )*/
                            return true
                          },
                        })}
                        control={control}
                        label="E-mail nauczyciela zgłaszającego"
                        error={errors.teacherEmail}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherEmail"
                      className="first"
                    >
                      <FormPrompt>
                        Podaj swój e-mail użyty do{'\u00A0'}zgłoszenia szkoły w
                        {'\u00A0'}programie Misja Kieszonkowe.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherEmail"
                  text={`Podaj swój e-mail użyty do zgłoszenia szkoły w programie Kieszonkowe 2022`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherName"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('teacherName', { required: true })}
                        control={control}
                        label="Imię i nazwisko nauczyciela"
                        error={errors.teacherName}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherName"
                    >
                      <FormPrompt>
                        Podaj swoje imię i{'\u00A0'}nazwisko.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherName"
                  text={`Podaj swoje imię i nazwisko `}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="region"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <RegionSelect
                        {...register('region', { required: true })}
                        control={control}
                        label="Województwo (w którym znajduje się placówka)"
                        error={errors.region}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="region"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy województwo, w{'\u00A0'}którym
                        znajduje się{'\u00A0'}placówka.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="region"
                  text={`Wybierz z listy województwo, w którym znajduje się placówka`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="city"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <CitySelect
                        {...register('city', { required: true })}
                        control={control}
                        region={region}
                        label="Miejscowość (w której znajduje się placówka)"
                        error={errors.city}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="city"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy miejscowość, w{'\u00A0'}której
                        znajduje się{'\u00A0'}placówka.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="city"
                  text={`Wybierz z listy miejscowość, w której znajduje się placówka`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="facilityName"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <SchoolSelect
                        {...register('facilityName', { required: true })}
                        control={control}
                        region={region}
                        city={city}
                        label="Nazwa placówki"
                        error={errors.facilityName}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="facilityName"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy nazwę placówki. Jeśli nie
                        możesz jej znaleźć, kliknij w{'\u00A0'}link poniżej.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="facility"
                  text={`Wybierz z listy nazwę placówki. Jeśli nie możesz znaleźć odpowiedniej placówki na liście kliknij w link poniżej.`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherPhone"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                      className="phone"
                    >
                      <Input
                        {...register('teacherPhone', {
                          required: true,
                          pattern: /\d{9,12}/,
                        })}
                        control={control}
                        label="Telefon nauczyciela zgłaszającego"
                        error={errors.teacherPhone}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherPhone"
                    >
                      <FormPrompt>Podaj swój numer telefonu.</FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherPhone"
                  text={`Podaj swój numer telefonu`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Row>
                      <Col xs={6}>
                        <FormElementWrapper
                          active={activeFocusID}
                          id="class"
                          onFocus={handlerFocus}
                          onBlur={handlerBlur}
                          className="class level"
                        >
                          <GenericSelect
                            {...register('classLevel', {
                              required: true,
                              pattern: /[3|4\-6]/,
                            })}
                            control={control}
                            options={classLevelOptions}
                            label="Poziom klasy"
                            error={errors.classLevel}
                          />
                        </FormElementWrapper>
                      </Col>
                      <Col xs={6}>
                        <FormElementWrapper
                          active={activeFocusID}
                          id="class"
                          className="tranparent class name"
                          onFocus={handlerFocus}
                          onBlur={handlerBlur}
                        >
                          <GenericSelect
                            {...register('className', { required: true })}
                            control={control}
                            options={classNameOptions}
                            label="Nazwa klasy"
                            error={errors.className}
                          />
                        </FormElementWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="class"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy poziom i{'\u00A0'}nazwę klasy.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="class"
                  text={`Wybierz z listy poziom i nazwę klasy`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={3}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="numberOfKids"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('numberOfKids', {
                          required: true,
                          validate: (value) => {
                            value = parseInt(value, 10)
                            if (value >= 1 && value <= 50) return true
                            return false
                          },
                        })}
                        min="1"
                        max="50"
                        control={control}
                        label="Liczba dzieci w klasie"
                        error={errors.numberOfKids}
                        type="number"
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} sm={{ size: 6, offset: 3 }}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="numberOfKids"
                      className="last"
                    >
                      <FormPrompt>
                        Podaj liczbę dzieci w{'\u00A0'}klasie.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="numberOfKids"
                  text={`Podaj liczbę dzieci w klasie`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormBottomWrapper xs={12}>
                <Row>
                  <Col xs={12} md={6}>
                    <Row>
                      <SubmitDropzoneWrapper show={showDropZone} xs={12}>
                        <DropzoneField
                          {...register('file', { required: true })}
                          control={control}
                          accept={[
                            'application/pdf',
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                          ]}
                          multiple={false}
                          maxFiles={1}
                          maxSize={31457280}
                          show={showDropZone}
                        />
                      </SubmitDropzoneWrapper>
                      <SubmitCheckboxWrapper xs={12}>
                        <SubmitDropzoneAddButton
                          as="button"
                          type="button"
                          show={showDropZone}
                          onClick={handlerShowDropZone}
                          className="btn"
                          error={errors.file}
                        >
                          <span>
                            Dodaj pracę
                            <br /> <span>(.pdf, .jpg, .png, pptx)</span>
                          </span>
                        </SubmitDropzoneAddButton>
                        <SubmitDropzoneDeleteButton
                          as="button"
                          type="button"
                          show={showDropZone}
                          onClick={handlerShowDropZone}
                          className="btn"
                        >
                          <span>Usuń plik</span>
                        </SubmitDropzoneDeleteButton>
                        <SubmitButton
                          as="button"
                          className="btn"
                          disabled={showPolicy1Modal || showPolicy2Modal}
                        >
                          Dalej
                        </SubmitButton>
                      </SubmitCheckboxWrapper>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>

                    </Row>
                  </Col>
                </Row>
              </SubmitFormBottomWrapper>
            </SubmitForm>

            {showPolicy1Modal && <Step2Form onSubmit={onSubmitStep2} />}
            {showPolicy2Modal && (
              <Step3Form isLoading={isLoading} onSubmit={onSubmit} />
            )}
          </Container>
        </SubmitWorkPageInnerWrapper>
        <Contact />
      </Layout>
    </IdProvider>
  )
}

export default SubmitEntryPage
