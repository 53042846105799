import { Link, withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const FormRegulationsOList = styled.ol`
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  a {
    color: ${(props) => props.theme.colorGreenLight};
  }
  li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      width: 3em;
    }
    li {margin: 0;
          &:before {
          content: counters(item, ".") ". ";
          }
        }
    }
  }
`

const FormRegulationsOListDeeperLevel = styled.ol`
  margin-left: 1em;
`

const FormRegulationsOListDeeperLevelAlpha = styled.ol`
  margin-left: 1em;
  counter-reset: alpha;
  li {
    &:before {
      counter-increment: alpha;
      content: '(' counter(alpha, lower-alpha) ') ';
    }
  }
`

export const Regulations = () => (
  <>
    <h2>
      Regulamin Konkursu dla Klas I-VI Szkół Podstawowych "Misja Kieszonkowe 2024"
    </h2>
    <FormRegulationsOList xs={12}>
      <li>
        <strong>SŁOWNIK POJĘĆ STOSOWANYCH W REGULAMINIE KONKURSU</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          <strong>Bank/Organizator</strong> – BNP Paribas Bank Polska S.A. z siedzibą w Warszawie (01-211) przy ul. Kasprzaka 2, wpisany do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000011571, NIP: 5261008546, o kapitale zakładowym w wysokości: 147 676 946,00 zł, w całości wpłaconym.
        </li>
        <li>
          <strong>Biuro Programu</strong> – biuro uruchomione przez Koordynatora Programu na potrzeby organizacji Konkursu, prowadzone pod adresem Garden of Words spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (01-209), ul. Hrubieszowska 6A, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000973765, NIP: 7011089585, o kapitale zakładowym w wysokości: 6.000 zł, nr tel.: 519877700, adres e-mail: kontakt@misjakieszonkowe.pl.
        </li>
        <li>
          <strong>Czas Trwania Konkursu</strong> – okres od dnia 12.02.2024 r. od godz. 10:00 do dnia 31.05.2024 r. do godziny 10:00, w którym przeprowadzany jest Konkurs oraz w którym można przesyłać Zgłoszenia Konkursowe.
        </li>
        <li>
          <strong>Formularz Konkursowy</strong> – formularz zamieszczony na Stronie Internetowej Konkursu www.misjakieszonkowe.pl, za pośrednictwem, którego dokonywane jest Zgłoszenie Konkursowe.
        </li>
        <li>
          <strong>Jury</strong> - grupa 3 (trzech) specjalistów powołanych do rozstrzygnięcia Konkursu oraz przyznania Nagród, w której skład wchodzą przedstawiciele Organizatora.
        </li>
        <li>
          <strong>Klasa</strong> – ogół uczniów uczęszczających do danego oddziału szkoły na poziomie klasy I, II, III lub - odpowiednio – IV, V, VI, którzy biorą aktywny udział w Konkursie w imieniu Szkoły. Klasa bierze udział w Konkursie poprzez zgłoszenie udziału Szkoły w Konkursie i wskazaniu przez Szkołę Klasy do podejmowania czynności w Konkursie.
        </li>
        <li>
          <strong>Komisja</strong> – grupa 3 (trzech) osób powoływana przez
          Organizatora w celu zapewnienia prawidłowego przebiegu Konkursu oraz
          rozpatrywania reklamacji.
        </li>
        <li>
          <strong>Konkurs</strong> – aktywność o nazwie „Konkurs dla Klas I-VI Szkół Podstawowych „Misja Kieszonkowe 2024” z zakresu dziedziny nauk społecznych – dyscypliny naukowej: ekonomia i finanse, zawierająca element rywalizacji, prowadzony przez Organizatora na zasadach wskazanych w Regulaminie, za pośrednictwem Strony Internetowej Konkursu, służącej popularyzacji wiedzy o przedsiębiorczości, finansach i bankowości, organizowany w ramach Programu.
        </li>
        <li>
          <strong>Koordynator Programu</strong> – Garden of Words spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (01-209), ul. Hrubieszowska 6A, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000973765, NIP: 7011089585, przeprowadzająca na zlecenie Organizatora czynności z zakresu realizacji Programu oraz Konkursu, w szczególności prowadząca Biuro Programu i odpowiadająca za kontakt z Uczestnikami.
        </li>
        <li>
          <strong>Laureat</strong> – Szkoła, w której działa Klasa, której Praca Konkursowa została wyłoniona zgodnie z punktem 6.4 lub 6.5 Regulaminu.
        </li>
        <li>
          <strong>Minicity</strong> – miniaturowe miasteczko przeznaczone dla dzieci, znajdujące się w Warszawie przy Placu Konesera 8. Więcej informacji na temat Minicity można znaleźć na stronie internetowej https://koneser.eu/miniciti-2/.
        </li>
        <li>
          <strong>Nagrody</strong> – Nagroda główna oraz Nagroda II stopnia.
        </li>
        <li>
          <strong>Nagroda główna</strong> – odpowiednio Nagroda główna dla klas I, II, III lub Nagroda główna dla klas IV-VI.
        </li>
        <li>
          <strong>Nagroda główna dla klas I-III</strong> – sfinansowana przez Organizatora jednodniowa wycieczka do Minicity wraz z zapewnieniem transportu do i z miejsca wycieczki oraz wizyta influencera w szkole Laureata, podczas której poprowadzi 45 minutowe spotkanie z zakresu finansów i bankowości, po uprzednim wyrażeniu zgody przez Dyrektora Szkoły lub inny podmiot, jeśli będzie wymagana taka zgoda.
        </li>
        <li>
          <strong>Nagroda główna dla klas IV-VI</strong> – sfinansowana przez Organizatora jednodniowa wycieczka do Minicity wraz z zapewnieniem transportu do i z miejsca wycieczki oraz wizyta influencera w szkole Laureata, podczas której poprowadzi 45 minutowe spotkanie z zakresu finansów i bankowości, po uprzednim wyrażeniu zgody przez Dyrektora Szkoły lub inny podmiot, jeśli będzie wymagana taka zgoda.
        </li>
        <li>
          <strong>Nagroda II stopnia</strong> – odpowiednio Nagroda II stopnia dla klas I, II, III lub Nagroda II stopnia dla klas IV-VI.
        </li>
        <li>
          <strong>Nagroda II stopnia dla klas I-III</strong> – nagrody rzeczowe w postaci 30 (słownie: trzydziestu) bawełnianych worków z logo Programu.
        </li>
        <li>
          <strong>Nagroda II stopnia dla klas IV-VI</strong> – nagrody rzeczowe w postaci 30 (słownie: trzydziestu) bawełnianych worków z logo Programu.
        </li>
        <li>
          <strong>Opiekun</strong> – pedagog/nauczyciel Klasy, opiekujący się Klasą w Szkole, która zgłosiła swój udział w Konkursie i wskazała Klasę do wykonywania czynności w imieniu Szkoły w ramach Konkursu.
        </li>
        <li>
          <strong>Partner MasterCard</strong> – Mastercard Europe SA, belgijska spółka zarejestrowana przez Sąd Gospodarczy w Nivelles w Belgii (numer w rejestrze przedsiębiorców: RPR 0448038446), z siedzibą pod adresem: 198/A, Chaussée de Tervuren, 1410 Waterloo, Belgia, posiadająca swój oddział w Polsce Mastercard Europe Spółka Akcyjna Oddział w Polsce, z siedzibą w Warszawie, pod adresem: ul. Plac Europejski 1, 00-844 Warszawa, wpisaną przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000345959, wspierająca Organizatora.
        </li>
        <li>
          <strong>Praca Konkursowa lub Utwór</strong> – Praca Konkursowa I-III lub Praca Konkursowa IV-VI.
        </li>
        <li>
          <strong>Praca Konkursowa I-III</strong> – praca wykonana przez Klasę I, II albo III i jej Opiekuna, spełniająca kryteria wskazane w Regulaminie, w postaci opisu (w formie tekstowej) organizacji i przebiegu wydarzenia o tematyce finansowej, z dołączonymi zdjęciami wykonanymi przez Opiekuna w trakcie tego wydarzenia, w oznaczonym w Regulaminie okresie i według określonych w Regulaminie kryteriów.
        </li>
        <li>
          <strong>Praca Konkursowa IV-VI</strong> - praca wykonana przez Klasę IV, V albo VI i jej Opiekuna, spełniająca kryteria wskazane w Regulaminie, w postaci opisu (w formie tekstowej) organizacji i przebiegu wydarzenia o tematyce finansowej, z dołączonymi zdjęciami wykonanymi przez Opiekuna w trakcie tego wydarzenia, w oznaczonym w Regulaminie okresie i według określonych w Regulaminie kryteriów.
        </li>
        <li>
          <strong>Program</strong> - skierowany do Szkół program edukacyjno-naukowy „Misja Kieszonkowe”, którego celem jest propagowanie wiedzy na temat finansów oraz bankowości. obowiązujący od 27.10.2022 r. Udział w Konkursie wymaga uprzedniej rejestracji do Programu dokonanej za pośrednictwem strony internetowej https://www.misjakieszonkowe.pl.
        </li>
        <li>
          <strong>Przewodniki</strong> - pięć przewodników dostępnych (do pobrania) na Stronie Internetowej Konkursu, zawierających wskazówki dotyczące organizacji przykładowych wydarzeń przez Klasy (w podziale na Klasy I-III i IV-VI), które mogą zostać zorganizowane w ramach w Konkursu, a następnie stanowić podstawę do wykonania Pracy Konkursowej.
        </li>
        <li>
          <strong>Regulamin</strong> – niniejszy dokument regulujący zasady Konkursu, opublikowany na Stronie Internetowej Konkursu.
        </li>
        <li>
          <strong>Regulamin Programu</strong> – oznacza regulamin Programu dostępny na Stronie Internetowej Konkursu.
        </li>
        <li>
          <strong>Strona Internetowa Konkursu</strong> – strona internetowa
          znajdująca się pod adresem https://www misjakieszonkowe.pl/.
        </li>
        <li>
          <strong>Szkoła</strong> – instytucja oświatowo – wychowawcza zapewniająca nauczanie w zakresie ramowych planów nauczania, publiczna albo niepubliczna, będąca ośmioletnią szkołą podstawową, znajdująca się na terenie Rzeczypospolitej Polskiej.
        </li>
        <li>
          <strong>Uczestnik Konkursu</strong> – Szkoła, która bierze udział w Konkursie poprzez Klasy oraz została zgłoszona do Programu oraz Konkursu przez osobę upoważnioną (uprawnioną) do reprezentowania Szkoły za pośrednictwem Strony Internetowej Konkursu. Szkoła może wziąć udział w Konkursie tyle razy, ile jest Klas w Szkole uprawnionych do wzięcia udziału w Konkursie.
        </li>
        <li>
          <strong>Zgłoszenie Konkursowe</strong> – udzielenie informacji wymaganych przez Formularz Konkursowy oraz przesłanie Pracy Konkursowej, za pośrednictwem Formularza Konkursowego.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>ZAGADNIENIA OGÓLNE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Konkurs jest organizowany przez Organizatora w ramach Programu „Misja Kieszonkowe”.
        </li>
        <li>
          Konkurs trwa przez Czas Trwania Konkursu, w którym to okresie możliwe jest dokonanie Zgłoszenia Konkursowego.
        </li>
        <li>
          Konkurs przeprowadzany jest na terenie Rzeczypospolitej Polskiej
        </li>
        <li>
          Celem Konkursu jest popularyzacja wiedzy na temat finansów i bankowości, przedsiębiorczości. Partner MasterCard wspiera Organizatora przy realizacji celów Konkursu.
        </li>
        <li>
          W Konkursie mogą brać udział jedynie Klasy ze Szkół, które zostały zgłoszone przez uprawnioną (upoważniona) osobę do Konkursu oraz Klasy te zostały wskazane przez Szkołę jako biorące udział w Konkursie.
        </li>
        <li>
          Zgłoszenie do Konkursu oraz uczestnictwo w nim są całkowicie dobrowolne i bezpłatne.
        </li>
        <li>
          Do Konkursu mogą być zgłoszone jedynie Klasy I-VI Szkół.
        </li>
        <li>
          Klasa za pośrednictwem Opiekuna, a także Szkoła mogą w każdej chwili zrezygnować z udziału w Konkursie poprzez przesłanie rezygnacji na adres e-mail: kontakt@misjakieszonkowe.pl bez obowiązku podawania przyczyn. Skutkiem rezygnacji jest to, że Szkoła lub tylko niektóre Klasy (w zależności od zakresu rezygnacji) nie biorą udziału w dalszej części Konkursu, a tym samym nie przysługują im Nagrody
        </li>
        <li>
          Przystąpienie Szkoły do udziału w Konkursie jest zależne od zaakceptowania postanowień Regulaminu Konkursu oraz wyrażenia zgody na udział w Konkursie przez dyrektora Szkoły. Przed wzięciem udziału w Konkursie osoba upoważniona (uprawniona) do reprezentowania Szkoły ma możliwość zapoznania się z treścią Regulaminu Konkursu oraz zaakceptowania jego treści, poprzez zaznaczenie odpowiedniego pola w Formularzu Konkursowym. Osoba upoważniona (uprawniona) do reprezentowania Szkoły i nie będąca jej dyrektorem akceptuje Regulamin wyłącznie po uzyskaniu zgody dyrektora Szkoły na udział w Konkursie. W Formularzu Konkursowym zostanie wskazana Klasa, która uczestniczy w Konkursie w ramach tego Zgłoszenia Konkursowego.
        </li>
        <li>
          Organizator zastrzega prawo wydłużenia terminu nadsyłania Zgłoszeń Konkursowych z ważnych przyczyn przez które rozumie się: (i) przesłanie liczby Prac Konkursowych mniejszej niż łączna liczba Nagród w pierwotnym terminie Czasu Trwania Konkursu, (ii) z uwagi na siłę wyższą, przez którą rozumie się niemożliwy do przewidzenia w momencie rozpoczynania Konkursu kataklizm, akt terroryzmu, społeczny lub gospodarczy kryzys uniemożliwiający normalną działalność Organizatora lub Szkoły, wojnę, epidemię. W takim przypadku Organizator jest uprawniony do wydłużenia Czasu Trwania Konkursu oraz dokonania zmiany Regulaminu. O wydłużeniu Czasu Trwania Konkursu oraz jego przyczynie Koordynator Programu poinformuje Uczestników poprzez umieszczenie informacji na Stronie Internetowej Konkursu oraz wysłanie korespondencji mailowej na adresy wskazane w Formularzu Konkursowym. Uczestnicy mogą nie zaakceptować zmiany, informując o tym Organizatora najpóźniej do dnia poprzedzającego dzień wprowadzenia zmiany, co spowoduje, że przestaną oni brać udział w Konkursie w dniu dostarczenia Organizatorowi oświadczenia o braku akceptacji wprowadzenia zmian.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>ZGŁOSZENIE KONKURSOWE - ZASADY UCZESTNICTWA W KONKURSIE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Warunki wzięcia udziału w Konkursie przez Szkołę:
          <FormRegulationsOList xs={12}>
            <li>
              Rejestracja danej Szkoły w Programie zgodnie z Regulaminem Programu i wskazanie danej Klasy jako biorącej udział w Programie,
            </li>
            <li>
              Uzyskanie zgody od dyrektora Szkoły na zgłoszenie Szkoły do Konkursu oraz uczestnictwo Klasy w Programie oraz Konkursie oraz innych zgód, jeżeli są/ będą wymagane do wzięcia przez Klasę udziału w Programie lub w Konkursie,
            </li>
            <li>
              Zapoznanie się z Regulaminem oraz zaakceptowanie jego postanowień
            </li>
            <li>
              wskazanie Klasy, która ma w ramach danego zgłoszenia Szkoły
              wykonywać czynności w Konkursie,
            </li>
            <li>
              Przygotowanie przez Klasę Pracy Konkursowej,
            </li>
            <li>
              Przesłanie kompletnego Zgłoszenia Konkursowego wraz z Pracą
              Konkursową, w Czasie Trwania Konkursu.
            </li>
          </FormRegulationsOList>
        </li>
        <li>
          Każde Zgłoszenie Konkursowe i Praca Konkursowa muszą przejść pozytywną weryfikację Biura Programu, obejmującą weryfikację prawidłowości Zgłoszenia Konkursowego, Pracy Konkursowej, w tym warunków Pracy Konkursowej pod kątem zgodności z zasadami określonymi dla Pracy Konkursowej w Regulaminie Konkursu, a w szczególności zgodności z postanowieniami punktu 4.1-4.7 Regulaminu Konkursu.
        </li>
        <li>
          W przypadku gdy po weryfikacji okaże się, że Praca Konkursowa wymaga uzupełnienia lub zmiany w zakresie formalnym, czyli zgodności z Regulaminem, Biuro Programu w terminie do 7 dni od przesłania Zgłoszenia Konkursowego skontaktuje się z Opiekunem telefonicznie (z następującym potwierdzeniem drogą mailową) lub za pośrednictwem korespondencji email, przekazując odpowiednie wskazówki dotyczące kwestii formalnych i umożliwiając ponowne przesłanie korespondencją email Pracy Konkursowej lub przesłanie uzupełnionych informacji. Zmiany i uzupełnienia Zgłoszenia Konkursowego mogą być dokonane w Czasie Trwania Konkursu oraz po jego upływie, jednak nie później niż do dnia 04.06.2024 r. do godz. 10:00.
        </li>
        <li>
          Osoba dokonująca Zgłoszenia Konkursowego powinna postępować zgodnie z instrukcjami wskazanymi w Formularzu Konkursowym. Wprowadzenie Pracy Konkursowej do Formularza Konkursowego polega na załączeniu zdjęć stanowiących Pracę Konkursową w formacie jpg, png lub pdf do Formularza Konkursowego, z zastrzeżeniem pkt 3.6. oraz uzupełnienie go o informacje wymagane w Formularzu Konkursowym.
        </li>
        <li>
          Zgłoszenia Konkursowe przekazane Organizatorowi w innej formie niż za pośrednictwem Formularza Konkursowego lub w innym terminie niż w Czasie Trwania Konkursu, nie będą brane pod uwagę.
        </li>
        <li>
          Praca Konkursowa, o której mowa w pkt 3.4. nie może mieć rozmiaru większego niż 30 MB. Prace Konkursowe o wyższym rozmiarze nie będą mogły być dołączone do Formularza Konkursowego, a tym samym nie będą brały udziału w Konkursie.
        </li>
        <li>
          Zdjęcia i teksty załączone do Pracy Konkursowej nie powinny zawierać jakichkolwiek znaków wodnych lub napisów i elementów graficznych nałożonych na nie, a także nazw lub znaków towarowych.
        </li>
        <li>
          Zgłoszenie Konkursowe uważa się za przesłane w chwili jego zarejestrowania na serwerze, na którym znajduje się Strona Internetowa Konkursu. Osoba dokonująca Zgłoszenia Konkursowego otrzyma od Biura Programu potwierdzenie jego przesłania korespondencją email, na adres wskazany podczas przesyłania Zgłoszenia Konkursowego.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>PRACE KONKURSOWE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Prace Konkursowe niekompletne lub nieuzupełnione w zgodnie z pkt 3.3. nie będą brane pod uwagę przy rozstrzygnięciu Konkursu oraz przyznawaniu Nagród.
        </li>
        <li>
          Praca Konkursowa, pod rygorem pominięcia jej w Konkursie, NIE MOŻE:
          <FormRegulationsOListDeeperLevel xs={12}>
            <li>
              przedstawiać ani propagować zachowań zabronionych przez prawo, nieobyczajnych lub godzących w zasady dobrego wychowania i kultury osobistej,
            </li>
            <li>
              naruszać praw autorskich, dóbr osobistych, znaków towarowych oraz innych dóbr prawnie chronionych osób trzecich, a w szczególności nazw, logotypów, znaków graficznych i słownych.
            </li>
          </FormRegulationsOListDeeperLevel>
        </li>
        <li>
          Praca Konkursowa przygotowana przez Opiekuna i uczniów Klasy pod rygorem pominięcia jej w Konkursie, MUSI:
          <FormRegulationsOListDeeperLevel xs={12}>
            <li>
              Opisywać jakie wydarzenie zostało zorganizowane przez Klasę, jego przebieg oraz atrakcje, o ile zostały zaplanowane;
            </li>
            <li>
              Wskazywać czas trwania wydarzenia poprzez wskazanie daty i godziny rozpoczęcia i zakończenia wydarzenia;
            </li>
            <li>
              Opisywać założenia przebiegu wydarzenia oraz ich realizację oraz zaangażowanie uczniów Klasy;
            </li>
            <li>
              Opisywać spotkanie z ekspertem/ekspertką z zakresu finansów lub inną formę jego/jej udziału w wydarzeniu, o ile zostanie zorganizowane takie spotkanie lub zaplanowana inna forma udziału eksperta/ekspertki.
            </li>
          </FormRegulationsOListDeeperLevel>
        </li>
        <li>
          Praca Konkursowa przygotowana przez Opiekuna i uczniów Klasy może:
          <FormRegulationsOListDeeperLevel xs={12}>
            <li>
              Określać jakie osoby poza uczniami Klasy, Opiekuna i pracowników Szkoły zostały zaproszone do udziału w wydarzeniu, z zastrzeżeniem punktu 4.3.4.
            </li>
          </FormRegulationsOListDeeperLevel>
        </li>
        <li>
          Nie jest dopuszczalne przygotowanie i zgłoszenie do Konkursu jednej i tej samej Pracy Konkursowej przez kilka Klas. W takim przypadku tylko najwcześniej zgłoszona Praca Konkursowa będzie brana pod uwagę przy rozstrzygnięciu Konkursu oraz przyznaniu Nagród.
        </li>
        <li>
          W Konkursie nie może brać udziału Praca Konkursowa, która uprzednio była wykorzystana, w całości lub części, do udziału w innym programie, projekcie lub konkursie (konkursach) pod rygorem pominięcia jej w Konkursie lub pozbawienia Laureata prawa do Nagród. Praca Konkursowa, która została zgłoszona do udziału w Konkursie lub jej fragmenty, nie mogą być wykorzystane z tytułu uczestnictwa w innym programie, projekcie lub konkursie (konkursach) zarówno w Czasie Trwania Konkursu, jak i przez okres 3 lat po jego zakończeniu.
        </li>
        <li>
          Aż do upływu Czasu Trwania Konkursu możliwa jest zmiana zgłoszonej wcześniej Pracy Konkursowej z inicjatywy Uczestnika. W celu dokonania zmian, osoba upoważniona (uprawniona) do reprezentowania Szkoły lub Opiekun powinni zgłosić taką chęć do Biura Programu pisemnie lub za pośrednictwem poczty elektronicznej na adres Biura Programu. W takim wypadku Organizator usuwa Pracę Konkursową znajdującą się w systemie, co umożliwia przesłanie zmienionej lub nowej Pracy Konkursowej. Zmiany mogą być dokonywane nie później niż przed upływem Czasu Trwania Konkursu.
        </li>
        <li>
          Opiekun i uczniowie Klasy mogą korzystać z Przewodników wyłącznie w celu organizacji wydarzenia w ramach Konkursu i wykonania Pracy Konkursowej.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>OŚWIADCZENIA</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>Przesyłając Pracę Konkursową, osoba przesyłająca:</li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>działając w imieniu Szkoły, oświadcza, że:</li>
          <FormRegulationsOListDeeperLevelAlpha xs={12}>
            <li>
              nie istnieją przeszkody faktyczne i prawne, w tym w szczególności wynikające z wewnętrznych regulacji obowiązujących Szkołę do tego by Szkoła, w przypadku wygrania przez Laureata w Konkursie, odebrała Nagrody oraz Szkoła jest uprawniona do odebrania Nagród bez konieczności uzyskiwania odrębnych zgód jakichkolwiek podmiotów i organów państwowych lub samorządowych lub podmiotów prywatnych;
            </li>
            <li>
              Szkoła wyznacza wskazaną w Formularzu Klasę do podjęcia działań w Konkursie, zgodnie z postanowieniami Regulaminu Konkursu oraz na posługiwanie się nazwą Szkoły na potrzeby przeprowadzenia Konkursu, opublikowania informacji o Laureatach (m.in. na Stronie Internetowej Konkursu, na stronach internetowych Organizatora oraz w innych cyfrowych i tradycyjnych mediach) oraz opublikowania informacji na temat Konkursu na Stronie Internetowej Konkursu;
            </li>
            <li>
              Szkoła uzyskała zgody na wykorzystanie wizerunków, dla celów realizacji Konkursu od wszystkich osób innych niż uczniowie Klasy, których wizerunek został utrwalony w Pracy Konkursowej;
            </li>
          </FormRegulationsOListDeeperLevelAlpha>
          <li>oświadcza w imieniu własnym, że:</li>
          <FormRegulationsOListDeeperLevelAlpha xs={12}>
            <li>
              jest autorem relacji oraz zdjęć z realizacji zajęć składających się na Pracę Konkursową, a twórcami lub współtwórcami Pracy Konkursowej są członkowie Klasy lub że posiada zgodę autora relacji oraz zdjęć składających się na Pracę Konkursową na przesłanie relacji lub zdjęć, w celach określonych w Regulaminie;
            </li>
            <li>
              przesłana Praca Konkursowa załączona do Formularza Konkursowego nie narusza żadnych praw osób trzecich, a w szczególności praw autorskich, praw własności przemysłowej, dóbr osobistych i innych praw i dóbr prawnie chronionych;
            </li>
          </FormRegulationsOListDeeperLevelAlpha>
          <li>oświadcza, że:</li>
          <FormRegulationsOListDeeperLevelAlpha xs={12}>
            <li>
              udostępniła rodzicom lub opiekunom prawnym uczniów Klasy Regulamin oraz uzyskała zgodę od nich wszystkich na wzięcie przez uczniów Klasy udziału w Konkursie, na zasadach określonych w Regulaminie Konkursu;
            </li>
            <li>
              rodzice lub opiekunowie prawni każdego z uczniów Klasy, twórców lub współtwórców Pracy Konkursowej, wyrazili w imieniu swoich podopiecznych zgodę na korzystanie z pracy autorstwa swojego podopiecznego, na potrzeby Konkursu, tj. do zgłoszenia Pracy Konkursowej, wyłonienia Laureatów oraz w celu rozstrzygnięcia Konkursu i w celach archiwalnych;
            </li>
            <li>
              rodzice lub opiekunowie prawni każdego z uczniów Klasy, których wizerunek został utrwalony na Pracy Konkursowej, wyrazili w imieniu swoich podopiecznych zgodę na wykorzystanie wizerunku swoich podopiecznych, na potrzeby Konkursu, tj. do przygotowania Pracy Konkursowej, zgłoszenia Pracy Konkursowej, wyłonienia Laureatów oraz w celu rozstrzygnięcia Konkursu i w celach archiwalnych.
            </li>
          </FormRegulationsOListDeeperLevelAlpha>
        </FormRegulationsOListDeeperLevel>
        <li>
          Praca Konkursowa nie może obejmować pracy uczniów Klasy, których
          opiekunowie prawni nie wyrazili zgód, o których mowa w punkcie 5.1
          powyżej.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>PRZEPROWADZENIE KONKURSU</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Uczestnik bierze udział w jednoetapowym Konkursie. Rozstrzygnięcie Konkursu następuje w etapach określonych w punktach 6.2, 6.3, 6.4, 6.5.
        </li>
        <li>
          Komisja sprawuje nadzór merytoryczny i formalny nad przebiegiem Konkursu. Komisja dokonuje weryfikacji Zgłoszeń Konkursowych, w szczególności Prac Konkursowych, pod względem ich zgodności z Regulaminem, a w przypadku uznania, że Zgłoszenie Konkursowe jest zgodne z Regulaminem przeprowadza ocenę Prac Konkursowych zgodnie z postanowieniem punktu 6.3.
        </li>
        <li>
          Ocena Pracy Konkursowej jest dokonywana poprzez przyznanie każdej Pracy Konkursowej od 1 do 6 punktów w ramach każdego z poniższych kryteriów:
        </li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>Kreatywność przygotowanej Pracy Konkursowej,</li>
          <li>Zgodność Pracy Konkursowej z tematyką i celem Konkursu,</li>
          <li>
            Zaangażowanie oraz wkład Klasy w tworzenie Pracy Konkursowej.{' '}
          </li>
        </FormRegulationsOListDeeperLevel>
        <li>
          Komisja spośród Zgłoszeń Konkursowych, na podstawie oceny
          przeprowadzonej zgodnie z punktem 6.3. wyłoni:
        </li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>
            20 (słownie: dwadzieścia) Prac Konkursowych I-III, które uzyskały największą liczbę punktów,
          </li>
          <li>
            20 (słownie: dwadzieścia) Prac Konkursowych IV-VI które uzyskały największą liczbę punktów.
          </li>
        </FormRegulationsOListDeeperLevel>
        <li>
          Prace Konkursowe wyłonione przez Komisję zgodnie z punktem 6.4, Jury samodzielnie oceni stosując odpowiednio kryteria i punktację określoną w 6.3 i na podstawie tej oceny wyłoni:
        </li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>
            2 (słownie: dwie) Prace Konkursowe I- III z największą liczbą punktów, które oprócz Nagrody II stopnia otrzymają Nagrodę główną.
          </li>
          <li>
            2 (słownie: dwie) Prace Konkursowe IV-VI z największą liczbą punktów, które oprócz Nagrody II stopnia otrzymają Nagrodę główną.
          </li>
        </FormRegulationsOListDeeperLevel>
        <li>
          Laureatami Konkursu są Szkoły, które zgłosiły do wykonania w Konkursie czynności Klasy, których Prace Konkursowe zostały wyłonione zgodnie z punktem 6.4 lub 6.5 Regulaminu.
        </li>
        <li>
          Lista Laureatów zostanie ogłoszona dnia 10.06.2024 r. na Stronie Internetowej Konkursu, poprzez wskazanie zwycięskich Klas i Szkoły, w której skład wskazane Klasy wchodzą. Szkoły zostaną powiadomione w terminie do 11.06.2024 r. o wynikach Konkursu telefonicznie lub drogą mailową na numer telefonu lub adres e-mail Szkoły i Opiekuna podane w Formularzu Konkursowym.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>NAGRODY KONKURSOWE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>Organizator w Konkursie przyzna:</li>
        <ol>
          <li>2 (dwie) Nagrody główne dla klas I-III,</li>
          <li>2 (dwie) Nagrody główne dla klas IV-VI,</li>
          <li>20 (dwadzieścia) Nagród II stopnia dla każdej Klasy z przedziału klas I-III,</li>
          <li>20 (dwadzieścia) Nagród II stopnia dla każdej Klasy z przedziału klas IV-VI.</li>
        </ol>
        <li>
          2 (dwóm) Klasom ze Szkół (Szkoły), które zostały Laureatami (Laureatem) w zakresie Prac Konkursowych I- III i otrzymały najwyższe oceny zostaną przyznane Nagrody główne dla klas I-III oraz Nagrody II stopnia dla klas I-III, a pozostałym 18 (osiemnastu) Klasom ze Szkół (Szkoły), które zostały Laureatami (Laureatem), w zakresie Pracy Konkursowej I-III wyłonionych zgodnie z punktem 6.4 zostanie przyznana wyłącznie Nagroda II stopnia dla klas I-III.
        </li>
        <li>
          2 (dwóm) Klasom ze Szkół (Szkoły), które zostały Laureatami (Laureatem), w zakresie Prac Konkursowych IV-VI i otrzymały najwyższe oceny, zostaną przyznane Nagrody główne dla klas IV-VI oraz Nagroda II stopnia dla klas IV-VI, a pozostałym 18 (osiemnastu) Klasom ze Szkół (Szkoły), które zostały Laureatami (Laureatem), w zakresie Pracy Konkursowych IV-VI wyłonionej zgodnie z punktem 6.4 zostanie przyznana Nagroda II stopnia dla klas IV-VI.
        </li>
        <li>
          Nagrody zostały ufundowane przez Organizatora.
        </li>
        <li>
          Nagrody nie podlegają zamianie na inne nagrody lub wyróżnienia, ani
          też na ich ekwiwalent pieniężny.
        </li>
        <li>
          Laureaci Nagrody głównej dla klas I-III oraz IV-VI, są zobowiązani do współpracy z Organizatorem przy realizacji wycieczki do Minicity, w szczególności zobowiązani są do wskazania liczby uczniów w klasie oraz miejsca ich odbioru. Nagroda główna dla klas I-III oraz IV-VI w postaci wycieczki do Minicity zostanie zorganizowana w terminie do 31.10.2024 r. Niezależnie od wycieczki do Minicity, w szkole Laureata wystąpi influencer, który poprowadzi spotkanie z zakresu finansów i bankowości, po uprzednim uzgodnieniu terminu na wystąpienie z Dyrektorem Szkoły lub innym podmiotem, od którego będzie wymagana taka zgoda. Spotkanie zostanie przeprowadzony nie później niż do 31.10.2024 r. W przypadku, gdy Laureatami są Klasy I-III oraz IV-VI z tej samej Szkoły, spotkanie zostanie zorganizowane tylko jeden raz.
        </li>
        <li>
          Nagrody II stopnia zostaną przekazane Laureatom w terminie do dnia 21.06.2024 r. na adres Szkoły.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>NADZÓR NAD PRAWIDŁOWOŚCIĄ PROWADZENIA KONKURSU</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Koordynator Konkursu ponosi odpowiedzialność za prawidłowy przebieg
          Konkursu.
        </li>
        <li>
          Organizator Konkursu, w celu zapewnienia prawidłowego przebiegu
          Konkursu, powołuje Komisję.
        </li>
        <li>
          Komisja rozpatruje reklamacje związane z przebiegiem Konkursu w
          terminie 14 dni od dnia jej otrzymania.
        </li>
        <li>
          Reklamacje dotyczące przebiegu Konkursu należy zgłaszać w Czasie Trwania Konkursu oraz w terminie 30 dni od daty przekazania Nagród, a w przypadku ich nieotrzymania – 30 dni od dnia, do którego miały być wysłane.
        </li>
        <li>
          Reklamacje mogą być zgłaszane w formie pisemnej - listem na adres Biura Programu wraz z dopiskiem na kopercie „Reklamacja – Konkurs Misja Kieszonkowe” lub mailem na adres kontakt@misjakieszonkowe.pl z tytułem maila „Reklamacja – Konkurs Misja Kieszonkowe”. Dopiski na kopercie i w tytule maila nie są warunkiem złożenia reklamacji, ale pomagają przyspieszyć jej rozpoznanie.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>DANE OSOBOWE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Administratorem danych osobowych w ramach Konkursu jest Organizator, tj. BNP Paribas Bank Polska S.A. z siedzibą w Warszawie (01-211), przy ul. Kasprzaka 2, wpisany do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000011571, NIP: 5261008546, o kapitale zakładowym w wysokości: 147 676 946,00 zł, w całości wpłacony.
        </li>
        <li>
          Administrator danych osobowych wyznaczył Inspektora Ochrony Danych, z którym można się skontaktować pisemnie na adres korespondencyjny Organizatora wskazany w punkcie 9.1 powyżej lub poprzez email: iodo@bnpparibas.pl.
        </li>
        <li>
          Organizator przetwarza dane osobowe następujących podmiotów danych: osoby, które dokonały Zgłoszenia Pracy Konkursowej do Konkursu oraz osób, które kontaktowały się w sprawie Konkursu lub Opiekunów Klas.
        </li>
        <li>Dane osobowe przetwarzane są w następujących celach:</li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>
            przeprowadzenia Konkursu, w tym weryfikacji i wyłonienia Laureatów w Konkursie, wydania Nagród oraz na potrzeby postępowania reklamacyjnego – podstawą prawną przetwarzania danych jest niezbędność przetwarzania do realizacji prawnie uzasadnionego interesu administratora, którym jest organizacja i realizacja Konkursu (art. 6 ust. 1 lit. f RODO);
          </li>
          <li>
            dochodzenia lub ochrony przed ewentualnymi roszczeniami Szkół lub innych osób z tytułu realizacji Konkursu – podstawą prawną przetwarzania danych jest niezbędność przetwarzania do realizacji prawnie uzasadnionego interesu administratora, którym jest dochodzenie lub ochrona przed roszczeniami związanymi z Konkursem (art. 6 ust. 1 lit. f RODO);
          </li>
          <li>
            realizacji ciążących na Organizatorze zobowiązań wynikających z powszechnie obowiązujących przepisów prawa, w szczególności wykonanie przyrzeczenia publicznego (art. 6 ust. 1 lit. c RODO).
          </li>
        </FormRegulationsOListDeeperLevel>
        <li>
          Na potrzeby Konkursu Organizator jako administrator danych osobowych przetwarza następujące dane osobowe: imię i nazwisko osoby która dokonała Zgłoszenia Pracy Konkursowej do Konkursu, Opiekuna Klasy, adres e-mail, numer telefonu kontaktowego. Na potrzeby rozpatrzenia reklamacji Organizator przetwarza następujące dane osobowe: imię i nazwisko, adres korespondencyjny lub adres e-mail, wszystkie inne dane osobowe, jakie osoba składająca reklamację zdecyduje się podać w reklamacji.
        </li>
        <li>
          Dane osobowe będą przekazywane podmiotom działającym na rzecz i zlecenie Administratora w szczególności Koordynatora Konkursu jak również innym podmiotom, tj. dostawcom usług teleinformatycznych, dostawcom usług kurierskich i pocztowych, oraz świadczącym usługi w zakresie wsparcia informatycznego i obsługi prawnej.
        </li>
        <li>
          Dane ww. osób, będą przetwarzane przez okres przedawnienia roszczeń w zakresie Konkursu, tj. 6 lat (art. 118 k.c.) od zakończenia Konkursu albo od dnia rezygnacji z udziału w Konkursie
        </li>
        <li>
          W uzasadnionych przypadkach, dane osobowe mogą być przetwarzane dłużej niż to wynika z postanowień ustępów poprzedzających, w szczególności w związku z ewentualnymi postępowaniami sądowymi lub administracyjnymi.
        </li>
        <li>
          Podmiotom danych przysługuje prawo żądania dostępu do ich danych osobowych oraz uzyskania ich kopii, ich poprawiania lub uzupełnienia, jeśli są nieprawidłowe lub niekompletne, żądania ich usunięcia, ograniczenia przetwarzania, przenoszenia danych.
        </li>
        <li>
          Podmiotom danych przysługuje również prawo sprzeciwu wobec przetwarzania ich danych osobowych z przyczyn związanych z ich szczególną sytuacją, w przypadku przetwarzania danych na podstawie uzasadnionego interesu administratora.
        </li>
        <li>
          W celu realizacji praw, o których mowa w 7.9 i 7.10 należy skierować
          mail na adres justyna.pietrewicz@bnpparibas.pl.
        </li>
        <li>
          Podmiotom danych przysługuje prawo wniesienia skargi do organu
          nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych (adres: ul.
          Stawki 2, 00 - 193 Warszawa)
        </li>
        <li>
          Podanie danych jest dobrowolne, przy czym jest warunkiem koniecznym do
          wzięcia udziału w Konkursie i rozpatrzenia reklamacji. Niepodanie
          danych będzie skutkować brakiem możliwości uczestnictwa w Konkursie
          oraz brakiem możliwości rozpatrzenia reklamacji przez Organizatora.
        </li>
        <li>
          Administrator danych osobowych nie będzie przekazywać danych osobowych
          odbiorcom spoza Europejskiego Obszaru Gospodarczego lub organizacjom
          międzynarodowym w rozumieniu RODO. Administrator danych osobowych nie
          będzie podejmował zautomatyzowanych decyzji, w tym nie będzie
          dokonywał profilowania.
        </li>
      </FormRegulationsOList>
      <li>
        <strong>POSTANOWIENIA KOŃCOWE</strong>
      </li>
      <FormRegulationsOList xs={12}>
        <li>
          Konkurs nie jest grą losową, której wynik zależy od przypadku w rozumieniu art. 2 ustawy z dnia z dnia 19 listopada 2009 r. o grach hazardowych (Dz.U. z 2022 r. poz. 888 z późn. zm.).
        </li>
        <li>
          W sprawach nieobjętych postanowieniami niniejszego Regulaminu Konkursu
          stosuje się powszechnie obowiązujące przepisy, w tym w szczególności
          przepisy Kodeksu Cywilnego.
        </li>
        <li>
          Organizator zastrzega sobie prawo do zmiany postanowień Regulaminu
          Konkursu z ważnych przyczyn. Za ważne przyczyny uznać należy m.in.:
        </li>
        <FormRegulationsOListDeeperLevel xs={12}>
          <li>
            usprawnienia techniczne Strony Internetowej Konkursu, w
            szczególności zmiany służące poprawie bezpieczeństwa użytkowników
            Strony Internetowej Konkursu lub poprawie komfortu korzystania ze
            Strony Internetowej Konkursu;
          </li>
          <li>
            zmianę firmy, formy prawnej lub siedziby Organizatora oraz
            Koordynatora Konkursu,
          </li>
          <li>
            zmiany techniczne, związane z prawidłowym funkcjonowaniem Strony
            Internetowej Konkursu,
          </li>
          <li>
            konieczność dostosowania Regulaminu Konkursu ze względu na zmianę
            lub wejście w życie nowych bezwzględnie obowiązujących przepisów
          </li>
          <li>
            wprowadzenie środków bezpieczeństwa, bezwzględnie koniecznych w celu
            zapewnienia bezpieczeństwa osób małoletnich, a także w celu
            zapewnienia prawidłowego przebiegu Konkursu. Zmiany w Regulaminie
            Konkursu nie będą naruszać praw nabytych przez Uczestników Konkursu.
          </li>
        </FormRegulationsOListDeeperLevel>
        <li>
          Organizator zastrzega sobie prawo do zmiany terminu i miejsca przeprowadzenia Konkursu, odbioru Nagród, z ważnych przyczyn organizacyjnych, do których należy: (i) siła wyższa, (ii) wprowadzenie lokalnych lub ogólnokrajowych obostrzeń utrudniających, bądź uniemożliwiających przeprowadzenie Konkursu. Organizator ma prawo zmienić Regulamin w związku z którąkolwiek z tych zmian.
        </li>
        <li>
          Organizator informuje Uczestników Konkursu o zmianach Regulaminu Konkursu, najpóźniej na 7 dni przed jego zmianą, poprzez publikację na Stronie Internetowej Konkursu wyraźnej informacji o zmianie, a także poprzez doręczenie zgłoszonym do Konkursu Opiekunom Klas informacji o zmianie, przynajmniej na 7 dni przed zmianą, za pośrednictwem poczty elektronicznej, w taki sposób, by Opiekunowie mieli możliwość pobrania dokumentu informującego o zmianach, jego zapisania i odtwarzania w dowolnej chwili. Zmiany Regulaminu Konkursu są wiążące, jeśli Opiekun nie wypowiedział udziału w Konkursie Klasy w terminie 7 dni od dnia doręczenia Opiekunowi Klasy zmian Regulaminu Konkursu, poprzez przesłanie informacji o wypowiedzeniu drogą elektroniczną na adres kontakt@misjakieszonkowe.pl.
        </li>
        <li>
          Organem nadzoru właściwym w sprawach ochrony konsumentów jest Prezes Urzędu Ochrony Konkurencji i Konsumentów z siedzibą w Warszawie, przy ul. Plac Powstańców Warszawy 1, 00-950 Warszawa. W sprawach indywidualnych bezpłatną pomoc prawną konsumenci mogą uzyskać u miejskich lub powiatowych rzeczników konsumentów.
        </li>
        <li>
          Niniejszy Regulamin wchodzi w życie z dniem 12.02.2024 r.
        </li>
      </FormRegulationsOList>
    </FormRegulationsOList>
  </>
)
